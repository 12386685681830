import Muuri from 'muuri';

function ImageGrid(scope) {
    var containers = scope.find('.js-sort-container');

    if (window['screen'] && window.screen.width >= 768) {

        setTimeout(function () {
            containers.find('.loading-circle').fadeOut();

            function debounce(fn, delay) {
                var timer = null;
                return function () {
                    var context = this, args = arguments;
                    clearTimeout(timer);
                    timer = setTimeout(function () {
                        fn.apply(context, args);
                    }, delay);
                };
            }

            if (containers.length > 0) {
                containers.each( function( index, element ) {  // loop over many containers
                    let container = $(element);
                    // console.log("now preping container", container);
                    var container_id = element.id;
                    var url = container.data('action');

                    var grid = new Muuri('#'+container_id, {
                        items: '.js-sort-element',
                        layoutOnResize: 100,
                        layoutOnInit: true,
                        layoutDuration: 300,
                        layoutEasing: 'ease',

                        // Sorting
                        sortData: {
                            id: function (item, element) {
                                return parseFloat(element.getAttribute('data-sort-id'));
                            }
                        },
                        // Drag & Drop
                        dragEnabled: container.data('drag-enabled'),
                        dragSort: container.data('drag-sort'),
                        dragSortPredicate: {
                            threshold: 30,
                            action: 'swap',
                        },
                    });

                    grid.sort('id');

                    grid.layout(true, function() {
                        container.trigger('layout:done');
                    });

                    container.on('image:removed', function(event) {
                        grid.remove(event.item[0], {removeElements: true});
                    });

                    window.addEventListener('load', function () {
                        grid.refreshItems().layout();
                    });

                    if (container.data('drag-enabled')) {
                        grid.on('move', debounce(function () {
                            var data = updateIndices();

                            $.ajax({
                                url: url,
                                type: 'put',
                                data: {'assets': data},
                                success: function (data) {
                                    console.log(data)
                                },
                                error: function (data) {
                                    console.log('error');
                                }
                            }); 

                        }, 4500));
                    }


                    function updateIndices() {
                        let data = {};
                        grid.getItems().forEach(function (item, i) {
                            let assetId = item.getElement().getAttribute('data-asset-id');
                            item.getElement().setAttribute('data-sort-id', i);
                            data[assetId] = {'sort': i};
                        });
                        grid.refreshSortData();
                        return data;
                    }
                });
            }
        }, 2000)
    } else {
        containers.find('.loading-circle').fadeOut();
    }
}

export default ImageGrid;