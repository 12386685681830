function Soundcloud(scope) {
    (function ($) {
          $.fn.scembed = function(){
          var datasource  = 'https://soundcloud.com/oembed';
          return this.each(function () {
              var container = $(this);
              var mediasource = $(container).attr("sc_url");
              console.warn(mediasource);
              var params = 'url=' + mediasource + '&format=json&iframe=true&maxwidth=400&maxheight=400&auto_play=false&show_comments=false';
              $.ajaxopts = $.extend($.ajaxopts, {
                                  url: datasource,
                                  data: params,
                                  dataType: 'json',
                                  crossDomain: true,     
                                  type: "GET",
                                  success: function (data, status, raw) {
                                      $(container).html(data.html);
                                      },
                                  error: function (data, e1, e2) {
                                      $(container).html("Can't retrieve player for <a class='ttu' href='" + mediasource + "'>"+ mediasource + "</a>");
                                  },
                              });
              $.ajax($.ajaxopts);
              });
          };
      })(jQuery);

      $(function(){
        // console.log("setting up soundcloud embed for", $("div.cs-embed").length, "links");

        $("div.sc-embed").scembed();
      });
}

export default Soundcloud;