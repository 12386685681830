function isClickable( obj, newTab ){
    var $this = obj;
    var link = $this.find('a:first');
    var href = link.attr('href');

    if (href == undefined) {
        return;
    }

    var target = link.attr('target');
    if( target == '_blank' || newTab ){
        window.open( href );
    }else{
        window.location.href = href;
    }
}

function Clickable(scope) {
    scope.find('.isClickable').on('click', function(evt){
        isClickable($(this));
        evt.preventDefault();
    });
}

export default Clickable;