function Scroll(scope) {
    function scrollTo ($target) {
        if (!$target || !$target.length) {
            // console.warn("Can't scroll to '" + $target + "'. Target element not found");
            return;
        }

        var duration = Math.abs($target.offset().top - window.scrollY) / 8 + 400;


        var offset = $target.offset().top - 100;

        $('html, body').stop().animate({
                scrollTop: offset
            },
            duration,
            'swing'
        );
    }


    scope.find('.js-scroll-to').on('click', function (evt) {
        evt.preventDefault();
        var $target = $($(this).attr('href'));
        var $otherTarget = $($(this).attr('data-target'));
        $target ? scrollTo($target) : null;
        $otherTarget ? scrollTo($otherTarget) : null;
    });

    if(scope.find('#results')) {
        setTimeout(function() {
            scrollTo(scope.find('#results'));
        }, 500)
    }
}

export default Scroll;