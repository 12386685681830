function redisplay() {
  $(".filter-item").hide();
  $("#tag_filter input:checked").each(function(idx, ele) {
    let tag_name = $(ele).attr("name");
    // console.log(`show .filter-item.tag_${tag_name}`);
    $(`.filter-item.tag_${tag_name}`).show();
  });
}

function TagFilter(scope) {
  if (scope.find("#tag_filter")) {
    $("#tag_filter input").on("click", function() {
      redisplay();
    });
    redisplay();
  }
}

export default TagFilter;
