function HoverFade (scope) {
    scope.find('.js-hover-fade').each(function() {
        var $this = $(this);
        var $targets = $($(this).attr('data-target'));
        $this.mouseenter(function() {
            $targets.addClass('js-hover-active');
        });

        $this.mouseleave(function() {
            $targets.removeClass('js-hover-active');
        });
    });
}

export default HoverFade;