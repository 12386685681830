function Navigation(scope) {
    initMobile(scope);
    expandSearch(scope);

    $(window).on('scroll', function (event) {
        requestAnimationFrame(checkSticky);
    });


    function checkSticky() {
        var scrollValue = $(window).scrollTop();
        if (scrollValue > 70) {
            scope.find('.navbar').addClass('affix');
        } else {
            scope.find('.navbar').removeClass('affix');
        }
    }

    $('.js-navbar-toggle').on('click', function () {
        var navbar = scope.find('.navbar');
        if (navbar.hasClass('is-open')) {
            navbar.removeClass('is-open');
        } else {
            navbar.addClass('is-open');
        }
    });

    function initMobile (scope) {
        if (!window['screen'] || window.screen.width < 768) {
            var navMobileItem = scope.find('.navMobile-item').clone(),
                navMobile = scope.find('#navMobile');
            navMobileItem.removeClass('d-none d-md-block');
            navMobile.append(navMobileItem);
        }
    }


    function expandSearch(scope) {
        function f(e) {
            $(e.target).parents(".search").length < 1 && (
                e.preventDefault(),
                    $(".search").removeClass("is-expanded"),
                    $("body").off("click", f)
            )
        }

        scope.find(".js-search").on("click", function (e) {
            var form = $(this).closest(".search");
            form.hasClass("is-expanded") || (
                e.preventDefault(),
                    form.addClass("is-expanded"),
                    form.children(".search__form").focus(),
                    $("body").on("click", f)
            )
        });
    }
}

export default Navigation;