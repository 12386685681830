import 'chosen-js/chosen.min.css';
import 'chosen-js';

function Chosen (scope) {
    scope.find('.js-chosen-select').each(function() {
        var $this = $(this);
        // console.log("upgrade selectobx", this, "to chosen");
        $this.chosen({
            width: "100%",
            no_results_text: "no results found:",
            inherit_select_classes: true,
            allow_single_deselect: true,
            placeholder_text_multiple: $this.data('default-text'),
            placeholder_text_single: $this.data('default-text'),
            single_backstroke_delete: false
        });
    });
}

export default Chosen;