import 'lightgallery/dist/js/lightgallery-all.min';
import 'lightgallery/dist/css/lightgallery.min.css';

import 'lg-video/dist/lg-video.min';

window.all_the_slides = [];


function Lightgallery(scope) {

    var lightboxes = scope.find('.js-lightbox');

    if(lightboxes.length == 0 ) {
        return
    }

    lightboxes.each(function () {
        let items = $(this).find('.js-lightbox__item:not(.slick-cloned)');
        // console.log(`there are ${items.length} items in lightbox with id #${this.id}`, this);
        if(items.length > 0 ) {
            let $lg = $('#' + this.id);
            $lg.lightGallery({
                selector: '.js-lightbox__item:not(.slick-cloned)',
                animateThumb: false,
                thumbnail: false,
                share: false,
                autoplay: false,
                autoplayControls: false,
                download: false,
                prevHtml: "<span class='pagination__arrow pagination__arrow--prev'></span>",
                nextHtml: "<span class='pagination__arrow pagination__arrow--next'></span>",
                youtubePlayerParams: {
                    modestbranding: 0,
                    showinfo: 0,
                    rel: 0,
                    color: 'white',
                    playsinline: true
                },
                vimeoPlayerParams: {
                    byline: 0,
                    portrait: 0,
                    color: '6A6A61'
                }
            });
            window.all_the_slides.push($lg);
        }
    });
}

export default Lightgallery;