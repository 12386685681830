function VimeoPreview(scope) {
    (function ($) {
        $.fn.vpembed = function(){
            var datasource  = 'https://vimeo.com/api/oembed.json';
            return this.each(function () {
                var container = $(this);
                var mediasource = $(container).attr("data-video-url");
                var params = 'url=' + mediasource + '&width=700';
                $.ajaxopts = $.extend($.ajaxopts, {
                    url: datasource,
                    data: params,
                    dataType: 'json',
                    success: function (data, status, raw) {
                    //   console.dir(data);
                    //   console.dir("should set src on ", container, "to ", data.thumbnail_url);
                        $(container).attr('src',data.thumbnail_url);
                    },
                    error: function (data, e1, e2) {
                        console.log("Can't retrieve player for " + mediasource);
                    }
                });
                $.ajax($.ajaxopts);
                
            });
        };
    })(jQuery);

    $(function(){
        // console.log("setting up vimeo preview embed for", $("img.vp-embed").length, "images");
        $("img.vp-embed").vpembed();
    });
}

export default VimeoPreview;