import * as parsley from 'parsleyjs';

function Parsley(scope) {
    var $forms = scope.find('.js-parsley');

    $forms.parsley(
        {
            successClass: 'has-success',
            errorClass: 'has-error',
            classHandler: function (_el) {
                return _el.$element.closest('.form-group');
            },
            errorsContainer: function (_el) {
                var errorContainer = _el.$element.closest('.form-group').find('.form-errors');
                if (errorContainer && errorContainer.length > 0) {
                    return errorContainer;
                }
            },
            // nur felder validieren die sichtbar sind
            excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled]"
        }
    );

    $forms.each(function () {
        var $form = $(this);

        $form.parsley().on('field:error', function () {
            var parsleyId = this.__id__;
            var fieldClass = this.__class__;

            if (fieldClass == 'ParsleyFieldMultiple') {
                parsleyId = 'multiple-' + this.$element.data('parsley-multiple');
            }

            this.$element.attr({
                'aria-invalid': "true",
                'aria-describedby': 'parsley-id-' + parsleyId
            });
        });

        $form.parsley().on('field:success', function () {
            this.$element.removeAttr('aria-invalid aria-describedby');
        });
    });
}

export default Parsley;
