import Sortable from 'sortablejs';

export default function SortableList(scope) {
    var draggableSelector = '.js-sortable-item';
    var handleSelector = '.js-sortable-drag-handle';

    var bindSortable = function(element) {
        var $element = $(element);
        var isDisabled = $element.attr('data-disabled') == 'true';
        var updateUrl = $element.attr('data-update-url');

        var sortable = new Sortable(element, {
            disabled: isDisabled,
            direction: 'vertical',
            animation: 200,
            easing: 'ease-in-out',
            draggable: draggableSelector,
            handle: handleSelector,
            swapThreshold: 0.5,
            dataIdAttr: 'data-item-id',
            onEnd: function() {
                var data = this.toArray();

                $.ajax({
                    url: updateUrl,
                    type: 'patch',
                    data: { ids: data },
                });
            }
        });
    }

    var enableSortable = function(element) {
        var sortable = Sortable.get(element);
        sortable.option('disabled', false);

        $(element)
            .attr('data-disabled', false);
            //.find(draggableSelector).children().not(handleSelector).hide();
    }

    var disableSortable = function(element) {
        var sortable = Sortable.get(element);
        sortable.option('disabled', true);

        $(element)
            .attr('data-disabled', 'true')
            .find(draggableSelector).children().not(handleSelector).show();
    }

    scope.on('click', '.js-enable-sortable-list', function() {
        var selector = $(this).attr('data-target');
        var $disableButton = $('.js-disable-sortable-list[data-target="' + selector + '"]');

        enableSortable(scope.find(selector).get(0));

        if ($disableButton) {
            $disableButton.show();
        }

        $(this).hide();
    });

    scope.on('click', '.js-disable-sortable-list', function() {
        var selector = $(this).attr('data-target');
        var $enableButton = $('.js-enable-sortable-list[data-target="' + selector + '"]');

        disableSortable(scope.find(selector).get(0));

        if ($enableButton) {
            $enableButton.show();
        }

        $(this).hide();
    });

    $('.js-sortable-list').each(function() {
        bindSortable(this);
    });
}
