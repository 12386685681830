function Alert(scope) {
    var notice = scope.find('.modal#alert');
    if(notice.length > 0) {
        notice.modal('show');
        $('body').addClass('blurred');
        notice.on('hide.bs.modal', function() {
            $('body').removeClass('blurred');
        })
    }
}

export default Alert;