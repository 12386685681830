function Fileupload(scope) {


    var drop = scope.find(".js-file-input input[type='file']"),
        dropWrapper = $('.js-drop-wrapper'),
        targetContainer = $('.js-file-target');

    drop.on('dragenter', function (e) {
        dropWrapper.addClass('drop-wrapper--dropping');
    }).on('dragleave dragend mouseout drop', function (e) {
        dropWrapper.removeClass('drop-wrapper--dropping');
    });


    $('.js-file-input').on('change', ':file', function (event) {
        var input = $(this),
            combinedSize = 0,
            maxSize = $("input[data-max-file-size='true']").val(),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            label = input.val().replace(/\\/g, '/').replace(/.*\//, '');

        console.log(input.attr('accept').split(','));

        var fileTypes = input.attr('accept').split(',') || [
            'application/pdf',
            'application/zip',
            'application/x-zip-compressed',
            'image/jpeg',
            'image/png',
            'image/svg+xml',
            'image/gif',
            'video/quicktime',
            'video/mpeg',
            'video/mp4',
            'audio/mpeg',
            'audio/mp3',
            'audio/wav'
        ];

        var $label = $(this).closest('.js-file-input').find('.js-file-input__label');
        var $label_filename = $label.find('.direct-upload__filename');
        var labelText = label;
        var $uploadInfo = $('.js-upload-info');
        $uploadInfo.text($uploadInfo.data('default-text'));
        $label.removeClass('parsley-error');


        if (numFiles == 0) {
            labelText = $label.data('default-text');
        } else if (numFiles > 1) {
            labelText = numFiles + ' ' + $label.data('file-input-multiple-text');
        }

        for (var i = 0; i < numFiles; i++) {
            combinedSize += input.get(0).files[i].size;

            if (!validFileType(input.get(0).files[i]) || combinedSize > maxSize) {
                labelText = $label.data('default-text');
                if (!validFileType(input.get(0).files[i])) {
                    $uploadInfo.text($uploadInfo.data('default-text') + " - " + $label.data('file-wrong-format'));
                }
                else if (combinedSize > maxSize) {
                    $uploadInfo.text($uploadInfo.data('default-text') + " - " + $label.data('file-max-size'));
                }
                $label.addClass('parsley-error');
                $uploadInfo.addClass('has-error');

                targetContainer.empty();
                input.val('');
                combinedSize = 0;
                break;
            }
            else {
                $uploadInfo.removeClass('has-error');
            }

            if (i === numFiles - 1) {
                labelText += ' (' + returnFileSize(combinedSize) + ')';
                handleSelectedFiles(event);
            }
        }

        $label_filename.text(labelText);


        function validFileType(file) {
            for (var i = 0; i < fileTypes.length; i++) {
                if (file.type === fileTypes[i]) {

                    return true;
                }
            }
            return false;
        }

        function returnFileSize(number) {
            if (number < 1024) {
                return number + ' bytes';
            } else if (number > 1024 && number < 1048576) {
                return (number / 1024).toFixed(1) + ' KB';
            } else if (number > 1048576) {
                return (number / 1048576).toFixed(1) + ' MB';
            }
        }

        function handleSelectedFiles(event) {
            const files = event.target.files // FileList object
            targetContainer.empty();
            if (!targetContainer.hasClass('js-crop-container')) {
                $('.drop-wrapper__body').hide();
            }

            // Loop through the FileList and render image files as thumbnails.
            for (var i = 0, f; f = files[i]; i++) {
                if (f.type.includes('image')) {
                    const reader = new FileReader();
                    reader.onload = (function (file) {
                        return function (e) {
                            const img = new Image();
                            img.src = e.target.result;
                            img.classList = 'img-fluid js-crop-image';
                            targetContainer.append(img);
                            $('html, body').animate({
                                scrollTop: $(targetContainer).offset().top - 200
                            }, 1000);

                            if (targetContainer.hasClass('js-crop-container')) {
                                targetContainer.trigger('image:added');
                            }
                        };
                    })(f);
                    reader.readAsDataURL(f);
                    // Read in the image file as a data URL.
                } else {
                    console.log(f)
                    targetContainer.append(`
                        <div class="add-asset d-flex justify-content-center align-items-center mb25">
                            <i class="fas fa-file fz40 mr10"></i> ${f.name}</div>
                    `)
                }

            }
        }
    });

// direct_uploads

    addEventListener("direct-upload:initialize", event => {
        const {detail} = event
        const {id, file} = detail
        var label = document.querySelector('.direct-upload'),
            progress = document.querySelector('.direct-upload__progress')


        $('html, body').animate({
            scrollTop: $(label).offset().top - 100
        }, 500);

        targetContainer.addClass('opacity-half');
        targetContainer.parent().find('.js-loading').removeClass('d-none');
        label.setAttribute('id', `direct-upload-${id}`)
        label.classList.add('direct-upload--pending')
        progress.setAttribute('id', `direct-upload-progress-${id}`)
    })

    addEventListener("direct-upload:start", event => {
        const {id} = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.remove("direct-upload--pending")
    })

    addEventListener("direct-upload:progress", event => {
        const {id, progress} = event.detail
        const progressElement = document.getElementById(`direct-upload-progress-${id}`)
        progressElement.style.width = `${progress}%`
    })

    addEventListener("direct-upload:error", event => {
        event.preventDefault()
        const {id, error} = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.add("direct-upload--error")
        element.setAttribute("title", error)
    })

    addEventListener("direct-upload:end", event => {
        const {id} = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.add("direct-upload--complete")
    })
}

export default Fileupload;