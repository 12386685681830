// NOTE: $, jquery, jQuery are available globally
// through webpack + config/webpack/environment.js

import Fileupload from './file-upload';
import Clickable from './isClickable';
import Chosen from './chosen-select';
import Parsley from './parsley'
import Lightgallery from './lightgallery';
import Slickslider from './slick-slider';
import Navigation from './navigation';
import Audio from './wavesurfer';
import Soundcloud from './soundcloud-embed';
import VimeoPreview from './vimeo-preview-embed';
import CropImage from './crop-image';
import Notice from './notice';
import Alert from './alert';
import ImageGrid from './image-grid';
import Scroll from './scroll-to';
import HoverFade from './hover-fade';
import TagFilter from './tag-filter';
import SortableList from './sortable-list';

document.addEventListener('DOMContentLoaded', () => {
    initModules();
})

function initModules(scope) {
    scope = scope || $('body');

    Fileupload(scope);
    Clickable(scope);
    Navigation(scope);
    Chosen(scope);
    Parsley(scope);
    Lightgallery(scope);
    Slickslider(scope);
    Audio(scope);
    Soundcloud(scope);
    VimeoPreview(scope);
    CropImage(scope);
    Notice(scope);
    Alert(scope);
    ImageGrid(scope);
    Scroll(scope);
    TagFilter(scope);
    SortableList(scope);
    HoverFade(scope);

    ajaxAddForm(scope);
    ajaxOnSubmit(scope);
    ajaxDestroy(scope);

}

function reInitModules(scope) {
    scope = scope || $('body');

    Chosen(scope);
    Parsley(scope);

    ajaxAddForm(scope);
    ajaxOnSubmit(scope);
    ajaxDestroy(scope);
}

function ajaxDestroy(scope) {
    scope.find('.js-ajax-destroy-btn').on('click', function (ev) {
        ev.preventDefault();
        console.log("this is .js-ajax-destroy-btn on ", ev.target);
        var $this = $(this),
            destroyEl = $(`[data-asset-id='${$this.data('asset')}']`);

        destroyEl = (destroyEl.length > 0 ? destroyEl : $this.closest('.js-ajax-destroy-element'));

        var modal = $this.closest('.modal');

        $this.attr('disabled', 'disabled');
        destroyEl.addClass('is-loading');
        $.ajax({
            url: $this.attr('href'),
            type: $this.data('-method'),
            success: function(data) {
                console.log('success in ', $this);

                if($this.closest('.js-sort-container').length > 0) {
                    $this.closest('.js-sort-container').trigger({type: 'image:removed', item: destroyEl});
                }
                else {
                    destroyEl.remove();
                }
            },
            error: function(data) {
                console.log('error');
                $this.removeAttr('disabled');
                destroyEl.removeClass('is-loading');
            }
        });
    });
}

//new role via ajax
function ajaxAddForm (scope) {
    scope.find('.js-ajax-new-btn').on('click', function(ev) {
        ev.preventDefault();
        var $this = $(this);
        $this.attr('disabled', 'disabled').addClass('is-loading');
        $.ajax({
            url: $this.attr('href'),
            type: 'GET',
            success: function(data) {
                var newForm = $('<div></div>').append(data);
                $this.fadeOut();
                $this.removeAttr('disabled').removeClass('is-loading');
                reInitModules(newForm);

                if($this.data('append-to')) {
                    var wrapper = $this.closest($this.data('wrapper')),
                        appendTo = wrapper.find($this.data('append-to'));
                    appendTo.empty().append(newForm);
                }

                if($this.data('replace')) {
                    var replaceEl = $this.closest($this.data('replace'));
                    replaceEl.replaceWith(newForm);
                }
            },
            error: function(data) {
                console.warn('error');
                $this.removeAttr('disabled').removeClass('is-loading');
            }
        });
    });
}

function ajaxOnSubmit(scope) {

    scope.find('.js-ajax-form').on('submit', function(ev) {
        ev.preventDefault();
        console.log("this is ajaxOnSubmit for form ", ev.target);
        var $this = $(this),
            data = $this.serialize();
        $this.addClass('is-loading');
        $.ajax({
            url: $this.attr('action') || $this.data('action'),
            type: $this.attr('method') || $this.data('method'),
            data: data,
            success: function(data) {
                var newContent = $(data);
                $this.removeClass('is-loading');

                if($this.data('append-to')) {
                    var wrapper = $this.closest($this.data('wrapper')),
                    appendTo = wrapper.find($this.data('append-to'));
                    appendTo.append(newContent);

                    if ($this.data('edit-btn')) {
                        wrapper.find($this.data('edit-btn')).fadeIn()
                    }
                    if ($this.data('clear-container')) {
                        wrapper.find($this.data('clear-container')).empty()
                    }
                }

                if($this.data('replace')) {
                    var replaceEl = $this.closest($this.data('replace')),
                        success = $('<div class="success" style="display: none"><i class="fas fa-check"></i></div>'),
                        parent = replaceEl.parent();
                    replaceEl.parent().append(success);
                    success.fadeIn();

                    setTimeout(function() {
                        success.fadeOut();
                    }, 4000)

                    replaceEl.replaceWith(newContent);
                }


                if ($this.data('reset-form')) {
                    $this.find('.js-chosen-select').val('').trigger("chosen:updated");
                    $this.find('input:not([type="submit"])').val('');
                    if(newContent.length) {
                        $('html, body').animate({
                            scrollTop: $(newContent).offset().top - 100
                        }, 1000);
                    }
                }

                reInitModules(newContent);
            },
            error: function(data) {
                console.log('error');
                $this.removeClass('is-loading');
                var replaceEl = $this.closest($this.data('replace')),
                    error = $('<div class="error"><i class="fas fa-times"></i></div>'),
                    parent = replaceEl.parent();
                replaceEl.parent().append(error);

                setTimeout(function() {
                    error.fadeOut();
                }, 5000)
            }
        });
    });
}
