import 'slick-carousel/slick/slick.js';
import 'slick-carousel/slick/slick.scss';


function Slickslider(scope) {

    var slider = scope.find('.js-slider'),
        slickOptions = {
            lazyLoad: 'progressive',
            autoplay: true,
            autoplaySpeed: 5000,
            rows: 1,
            slidesToShow: 1,
            infinite: true,
            accessibility: true,
            adaptiveHeight: false,
            arrows: true,
            dots: true,
            draggable: true,
            edgeFriction: 0.30,
            swipe: true,
            nextArrow: '<button type="button" class="slick-next"><span class="slick-arrow__arrow"></span></button>',
            prevArrow: '<button type="button" class="slick-prev"><span class="slick-arrow__arrow"></span></button>'
        };

    slider.each(function() {
        var $this = $(this),
            options = $.extend({}, slickOptions);

        if($this.hasClass('js-editions-slider')) {
            $.extend(options, {
                slidesToScroll: 1,
                slidesToShow: 3,
                centerMode: true,
                autoplay: false,
                dots: false,
                rows: 0,
                slidesPerRow: 3,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,

                    }
                }]
            });
        }

        $this.slick(options);
    });
}

export default Slickslider;