import * as Wavesurfer from 'wavesurfer.js';

function Audio (scope) {
    var audios = scope.find('.js-audio-container');
    audios.each(function() {
        var $this = $(this),
            audioContainer = $this.find('.js-audio')[0],
            audioPlay = $this.find('.js-audio-play'),
            audioUrl = $this.data('media-url');


        var wavesurfer = Wavesurfer.create({
            container: audioContainer,
            cursorColor: '#363534',
            progressColor: '#611427',
            waveColor: '#6A6A61'
        });


        wavesurfer.load(audioUrl);

        wavesurfer.on('ready', function () {
            $this.find('.loading-circle').fadeOut();
        });


        audioPlay.on('click', function() {
            wavesurfer.playPause();
            $(this).find('.audio-play__icon').toggleClass('d-none')
        });
    });
}

export default Audio;