import * as Cropper from 'cropperjs/dist/cropper.min';
import 'cropperjs/dist/cropper.min.css';

function CropImage(scope) {
    var cropContainer = scope.find('.js-crop-container'),
        image = scope.find('.js-crop-image')[0],
        cropper = null;

    if (image) {
        var coords = JSON.parse(image.dataset.croppedData)
        var data = {
            x: parseInt(coords.x),
            y: parseInt(coords.y),
            width: parseInt(coords.w),
            height: parseInt(coords.h),
            rotate: 0,
            scaleX: 1,
            scaleY: 1
        };


        cropper = new Cropper(image, {
            dragMode: 'move',
            viewMode: 1,
            data: data,
            autoCrop: true,
            aspectRatio: 1 / 1,
            autoCropArea: 1,
            restore: false,
            guides: false,
            center: true,
            highlight: false,
            cropBoxMovable: true,
            cropBoxResizable: true,
            toggleDragModeOnDblclick: false,
            movable: true,
        });
    }

    var form = scope.find('.js-crop-form');

    form.on('submit', function(event) {
        if(cropper != null) {
            data = cropper.getData(true);
            var cropped_data = {x: data.x, y: data.y, w: data.width, h: data.height};

            $.each(cropped_data, function(key, value) {
                form.find(`#project_cropped_data_${key}`).val(value);
            });
        }
    });

    cropContainer.on('image:added', function() {
        image = $(this).find('.js-crop-image')[0];

        cropper = new Cropper(image, {
            dragMode: 'move',
            viewMode: 1,
            aspectRatio: 1 / 1,
            autoCropArea: 0.8,
            restore: false,
            guides: false,
            center: true,
            highlight: true,
            cropBoxMovable: true,
            cropBoxResizable: true,
            toggleDragModeOnDblclick: false,
            movable: true,
        });
    });
}

export default CropImage;